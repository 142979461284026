import { noop } from "lodash";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { Event } from "@circle-react/types/Event";
import { CustomizeUI } from "@circle-react-shared/CustomizeUI";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { ModalCloseButton } from "@circle-react-shared/ModalsV2/FullScreenModal/components/ModalCloseButton";
import { Form } from "@circle-react-uikit/Form";
import { EventsBody, EventsFooter, EventsHeader } from "./Common";
import { Actions } from "./Common/EventsHeader/Actions";
import { Loading } from "./Common/Loading";
import { MobilEventNavigation } from "./MobileEventNavigation";
import { useEventsV3 } from "./hooks/useEventsV3";
import { SECTIONS } from "./utils";

export interface EventsModalProps {
  isOpen: boolean;
  onClose: () => void;
  event?: Event;
  spaceId?: number | null;
  locationType?: string;
  shouldClearEventStateOnHide?: boolean;
  initialSection?: string;
}
export const EventsModal = ({
  isOpen,
  onClose,
  event: originalEvent,
  spaceId,
  locationType: initialLocationType,
  shouldClearEventStateOnHide = false,
  initialSection = SECTIONS.basicInfo,
}: EventsModalProps) => {
  const {
    event,
    isEditMode,
    isCreating,
    isDirty,
    isStartsAtDirty,
    locationType,
    setLocationType,
    setValueRef,
    triggerFormSubmitRef,
    formDefaultValues,
    title,
    handleClose,
    onSubmit,
    onSave,
    onPublish,
    validateAndSetCurrentSection,
    triggerValidationRef,
    setIsDirty,
    setStartsAtDirty,
    mutation,
    isLiveLocation,
    setPaywallAttributesDirty,
    shouldHideAllEventOption,
  } = useEventsV3({
    spaceId,
    event: originalEvent,
    shouldClearEventStateOnHide,
    locationType: initialLocationType,
    onClose,
  });

  const isSmScreen = useSmScreenMediaQuery();

  return (
    <>
      {/* This close button was moved here to prevent the useMentions from Trix editor closing the Modal when inserting a mention*/}
      {!isSmScreen && (
        <ModalCloseButton
          onClick={handleClose}
          className="z-[1100]"
          isVisible={isOpen}
        />
      )}
      <FullScreenModal
        isOpen={isOpen}
        shouldShowCloseButton={false}
        contentPortalId="events"
        onClose={noop}
      >
        <CustomizeUI
          formId="events"
          sections={Object.values(SECTIONS)}
          initialSection={
            isSmScreen && isEditMode ? SECTIONS.overview : initialSection
          }
          title={title}
          onExit={handleClose}
          onSave={(event: any) => onSubmit(event, {})}
          isSaving={mutation.isLoading}
        >
          <FullScreenModal.Header
            className={classNames("!px-5 !py-3 md:!px-6 md:!py-5", {
              "!border-0": isCreating,
            })}
          >
            <EventsHeader
              isEditMode={isEditMode}
              isCreating={isCreating}
              event={event}
              onPublish={onPublish}
              handleClose={handleClose}
              validateAndSetCurrentSection={validateAndSetCurrentSection}
            />
          </FullScreenModal.Header>
          <FullScreenModal.Body>
            {isSmScreen && (
              <MobilEventNavigation
                validateAndSetCurrentSection={validateAndSetCurrentSection}
                event={originalEvent}
                isEditMode={isEditMode}
                title={title}
              />
            )}
            <Form
              id="events"
              onSubmit={onSubmit}
              passThroughProps={{ onCancel: handleClose }}
              defaultValues={formDefaultValues}
              triggerFormSubmitRef={triggerFormSubmitRef}
              submitDirtyOnly={isEditMode}
            >
              {!isCreating && (
                <EventsBody
                  event={event}
                  triggerValidationRef={triggerValidationRef}
                  setValueRef={setValueRef}
                  setIsDirty={setIsDirty}
                  setLocationType={setLocationType}
                  locationType={locationType}
                  isLiveLocation={isLiveLocation}
                  setStartsAtDirty={setStartsAtDirty}
                  setPaywallAttributesDirty={setPaywallAttributesDirty}
                />
              )}
              {isCreating && <Loading />}
              {isSmScreen && <Actions event={event} onPublish={onPublish} />}
            </Form>
          </FullScreenModal.Body>
          <FullScreenModal.Footer
            className={classNames({ "!border-0 !p-0": isCreating || !isDirty })}
          >
            <EventsFooter
              event={event}
              isCreating={isCreating}
              isDirty={isDirty}
              onSubmit={onSave}
              locationType={locationType}
              isStartsAtDirty={isStartsAtDirty}
              shouldHideAllEventOption={shouldHideAllEventOption}
            />
          </FullScreenModal.Footer>
        </CustomizeUI>
      </FullScreenModal>
    </>
  );
};

import { useMutation, useQuery } from "react-query";
import type { UseMutationOptions } from "react-query";
import type { ApiError } from "@/react/config/CustomErrors";
import type { GamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/types";
import {
  reactQueryGet,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const GAMIFICATION_SETTINGS_QUERY_KEY = "gamification/settings";

export const useGamificationSettings = () =>
  useQuery<GamificationSettings>({
    queryKey: GAMIFICATION_SETTINGS_QUERY_KEY,
    queryFn: () => reactQueryGet(internalApi.gamification.settings()),
  });

export const useUpdateGamificationSettingsMutation = (
  options?: UseMutationOptions<unknown, ApiError, any>,
) =>
  useMutation<unknown, ApiError, any>({
    mutationFn: data =>
      reactQueryPut(internalApi.gamification.settings(), data),
    ...options,
  });
